import { render, staticRenderFns } from "./LoginRestrictionsHelp.vue?vue&type=template&id=acc5198c&scoped=true&"
import script from "./LoginRestrictionsHelp.vue?vue&type=script&lang=js&"
export * from "./LoginRestrictionsHelp.vue?vue&type=script&lang=js&"
import style0 from "./LoginRestrictionsHelp.vue?vue&type=style&index=0&id=acc5198c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acc5198c",
  null
  
)

export default component.exports